import { inject, Injectable } from '@angular/core';
import { NavigationExtras, Params } from '@angular/router';
import {
  BreakpointsTypes,
  CardStatusEnum,
  IGameSettings,
  IInventoryItem,
  IInventoryRequestParams,
  ModalNames,
  NewPanel,
  StateActionStatus,
  TradeOriginalItem,
} from '@dev-fast/types';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { xorBy } from 'lodash-es';
import { combineLatest, map, Observable, of, pairwise, startWith, switchMap, takeWhile, tap, timer } from 'rxjs';

import { AuthService } from '@app/auth';
import { LanguageService } from '@app/core/language-service';
import { ConfirmParticipateLegacySuccess, GamesState, ParticipateLegacy } from '@app/core/state/games-store';
import { ChangeInventoryPage, ChangeParamsInventory, GetInventoryItems, InventoryState } from '@app/core/state/inventory';
import { ClosePanel, LayoutState, ToggleNewPanel } from '@app/core/state/layout';
import { OpenModal } from '@app/core/state/modals';
import { actionLoadStatus, BehaviorSubjectItem } from '@app/shared/utils';
import { ParticipationPanelEngine } from '@app/widgets/participation-panel';

@Injectable()
export class ParticipationPanelCommonService implements ParticipationPanelEngine {
  #_authService = inject(AuthService);
  #_store = inject(Store);
  #_languageService = inject(LanguageService);
  #_actions$ = inject(Actions);

  isAuth$: Observable<boolean> = this.#_authService.isAuth$.pipe(
    startWith(false),
    pairwise(),
    map(([previous, current]) => {
      if (previous && !current) {
        this.unselectItems();
        this.#_store.dispatch(new ClosePanel(NewPanel.BET));
      }

      return current;
    }),
  );

  @Select(InventoryState.items)
  readonly inventoryItemsClear$!: Observable<IInventoryItem[] | null>;
  @Select(InventoryState.maxInventoryPages)
  readonly maxInventoryPages$!: Observable<number | null>;

  @Select(GamesState.myTradeItems)
  readonly participatedItemsFromGame$!: Observable<TradeOriginalItem[] | null>;
  @Select(GamesState.currentGameSettings)
  readonly settings$!: Observable<IGameSettings | null>;
  @Select(LayoutState.breakpoints)
  breakpoints$!: Observable<BreakpointsTypes | null>;
  @Select(LayoutState.activePanelNew)
  readonly activePanelNew$!: Observable<NewPanel[]>;
  @Select(InventoryState.inventoryParams)
  readonly inventoryParams$!: Observable<IInventoryRequestParams>;

  readonly selectedItemsSubject$ = new BehaviorSubjectItem<IInventoryItem[]>([]);
  readonly selectedItems$: Observable<IInventoryItem[]> = this.selectedItemsSubject$.value$;

  readonly temporaryItemsSubject$ = new BehaviorSubjectItem<IInventoryItem[]>([]);
  readonly temporaryItems$: Observable<IInventoryItem[]> = this.temporaryItemsSubject$.value$;

  readonly disabledItemsSubject$ = new BehaviorSubjectItem<IInventoryItem[]>([]);
  readonly disabledItems$: Observable<IInventoryItem[]> = this.disabledItemsSubject$.value$;

  readonly inventoryLoadStatus$: Observable<StateActionStatus> = actionLoadStatus(this.#_actions$, GetInventoryItems);
  readonly addBetStatus$: Observable<StateActionStatus> = actionLoadStatus(this.#_actions$, ParticipateLegacy);

  readonly timer$ = this.#_actions$.pipe(
    ofActionSuccessful(ConfirmParticipateLegacySuccess),
    tap((value) => {
      this.temporaryItemsSubject$.value = [
        ...this.temporaryItemsSubject$.value,
        ...this.selectedItemsSubject$.value.map((item) => ({ ...item, participateStatus: CardStatusEnum.TIMER })),
      ];
      this.resetSelectedItems();
    }),
    map((action) => {
      const response = action.payload as { items: any[]; timeout: number };
      return (response.timeout ? response.timeout : 0) * 1000;
    }),
    switchMap((timeout) =>
      timer(0, 1000).pipe(
        map((i) => timeout - i * 1000),
        takeWhile((value) => value >= 0),
      ),
    ),
    tap((timeout) => {
      if (timeout === 0) {
        //при назначении статусов если ниодин не подошел то основываться на нем. Этот массив очищать при подгрузке новых айтемсов?
        this.disabledItemsSubject$.value = [
          ...this.disabledItemsSubject$.value,
          ...this.temporaryItemsSubject$.value.map((item) => ({ ...item, participateStatus: CardStatusEnum.DISABLED, isFrozen: true })),
        ];
        this.resetTemporaryItems();
      }
    }),
  );
  readonly currentSelectedSum$: Observable<number> = this.selectedItems$.pipe(
    map((items) => items.reduce((acc, item) => acc + item.price, 0)),
  );
  readonly temporarySum$: Observable<number> = this.temporaryItems$.pipe(map((items) => items.reduce((acc, item) => acc + item.price, 0)));
  readonly newItemsSum$: Observable<number> = combineLatest([this.currentSelectedSum$, this.temporarySum$]).pipe(
    map(([selectedSum, temporarySum]) => selectedSum + temporarySum),
  );
  readonly newItemsCount$: Observable<number> = combineLatest([this.selectedItems$, this.temporaryItems$]).pipe(
    map(([selectedItems, temporaryItems]) => selectedItems.length + temporaryItems.length),
  );
  readonly isPanelOpened$: Observable<boolean> = this.activePanelNew$.pipe(map((panels) => panels.includes(NewPanel.BET)));
  readonly participatedItems$: Observable<TradeOriginalItem[] | null> = combineLatest([
    this.participatedItemsFromGame$,
    this.inventoryItemsClear$,
  ]).pipe(
    switchMap(([participatedItems, inventoryItems]) => {
      const filteredParticipatedItemsByInventory = participatedItems
        ? participatedItems?.filter((participatedItem) => {
            return !inventoryItems?.some((inventoryItem) => inventoryItem.id === participatedItem.userInventoryItemId);
          })
        : [];
      return of(filteredParticipatedItemsByInventory);
    }),
  );
  readonly participatedItemsSum$: Observable<number> = this.participatedItemsFromGame$.pipe(
    map((items) => {
      if (!items) {
        return 0;
      }
      return items.reduce((acc, item) => acc + item.price, 0);
    }),
  );
  readonly inventoryItems$ = combineLatest([
    this.inventoryItemsClear$,
    this.selectedItems$,
    this.temporaryItems$,
    this.participatedItemsFromGame$.pipe(
      startWith(null),
      pairwise(),
      map(([prev, cur]) => {
        if (prev !== null && cur === null) {
          this.getInventoryItems();
        }
        return cur;
      }),
    ),
    this.disabledItems$,
    this.settings$,
    this.currentSelectedSum$,
    this.temporarySum$,
  ]).pipe(
    map(([inventoryItems, selectedItems, temporaryItems, participatedItems, disabledItems, settings, selectedSum, temporarySum]) => {
      const normParticipatedItems: IInventoryItem[] = participatedItems
        ? participatedItems.map((item) => {
            return {
              id: item.userInventoryItemId,
              participateStatus: CardStatusEnum.PARTICIPATED,
              inventoryItemId: item.inventoryItemId,
              icon: item.icon,
              color: item.color,
              price: item.price,
              name: item.name,
              type: 'skin',
              available: true,
              exterior: '',
              rarity: '',
              skin: '',
              shortName: '',
              statTrak: false,
              weapon: '',
            };
          })
        : [];
      if (inventoryItems === null) {
        return null;
      }
      const isInventoryItemsHasAllTemporaryItems = temporaryItems.every((tempItem) =>
        inventoryItems.some((inventoryItem) => inventoryItem.id === tempItem.id),
      );
      const getTemporaryItemsWithoutInventoryItems = (): IInventoryItem[] =>
        temporaryItems.filter((tempItem) => !inventoryItems.some((inventoryItem) => inventoryItem.id === tempItem.id));
      const normInventoryItems = !isInventoryItemsHasAllTemporaryItems
        ? [...getTemporaryItemsWithoutInventoryItems(), ...inventoryItems]
        : [...inventoryItems];
      return [
        ...normParticipatedItems.filter((participatedItem) => {
          return !inventoryItems?.some((inventoryItem) => inventoryItem.id === participatedItem.id);
        }),
        ...normInventoryItems.map((item) => {
          const isSelected = selectedItems.some((selectedItem) => selectedItem.id === item.id);
          const isTemporary = temporaryItems.find((temporaryItem) => temporaryItem.id === item.id);
          const isParticipated = participatedItems
            ? participatedItems.some((participatedItem) => participatedItem.userInventoryItemId === item.id)
            : false;
          const noValid =
            this.#_noValidMaxValues(
              selectedItems?.length || 0,
              participatedItems?.length || 0,
              temporaryItems?.length || 0,
              settings?.maxItemsPerTrade || 0,
              settings?.maxBet || null,
              selectedSum,
              temporarySum,
            ) && !isSelected;
          const isDisabled = disabledItems.some((disabledItem) => disabledItem.id === item.id);
          const participateStatus: CardStatusEnum | undefined = isSelected
            ? CardStatusEnum.SELECTED
            : isTemporary
              ? isTemporary.participateStatus
              : isParticipated
                ? CardStatusEnum.PARTICIPATED
                : isDisabled || noValid
                  ? CardStatusEnum.DISABLED
                  : CardStatusEnum.DEFAULT;
          return {
            ...item,
            participateStatus,
            isFrozen: isDisabled,
          };
        }),
      ];
    }),
  );
  getInventoryItems = (params?: IInventoryRequestParams, buffer = false): void => {
    this.#_store.dispatch(
      params && params.page && params.page > 1 ? new ChangeInventoryPage(params.page) : new ChangeParamsInventory({ ...params }),
    );
  };
  unselectItems = (): void => {
    this.selectedItemsSubject$.value = [];
  };
  togglePanel = (): void => {
    this.#_store.dispatch(new ToggleNewPanel(NewPanel.BET));
    this.resetSelectedItems();
  };

  onItemClick = (inventoryItem: IInventoryItem): void => {
    const selectedItems = this.selectedItemsSubject$.value;
    this.selectedItemsSubject$.value = xorBy(selectedItems, [inventoryItem], (item) => item.id);
  };
  placeBet(items: IInventoryItem[]): void {
    this.#_store.dispatch(new ParticipateLegacy(items));
  }
  resetSelectedItems(): void {
    this.selectedItemsSubject$.value = [];
  }
  resetTemporaryItems(): void {
    this.temporaryItemsSubject$.value = [];
  }
  navigateTo(path: string, queryParams?: Params | undefined, extras?: NavigationExtras | undefined): void {
    this.#_store.dispatch(new Navigate([this.#_languageService.getCurrentLangUrl(path)], queryParams, extras));
    this.resetSelectedItems();
  }

  openModal(modal: ModalNames, params?: any): void {
    this.#_store.dispatch(new OpenModal(modal, params));
    this.resetSelectedItems();
  }
  #_noValidMaxValues(
    selectedCount: number,
    participatedCount: number,
    temporaryItemsCount: number,
    maxItemsPerTrade: number,
    maxBet: number | null,
    selectedSum: number,
    temporarySum: number,
  ): boolean {
    return selectedCount + participatedCount + temporaryItemsCount >= maxItemsPerTrade || (!!maxBet && selectedSum + temporarySum > maxBet);
  }
}
